

import { Vue, Component } from "vue-property-decorator";

import liveData from "@/store/Modules/liveData";

@Component
export default class Login extends Vue {
  liveData!: any;
  username = "";
  password = "";

  created() {
    this.liveData = liveData;
    if (this.liveData.state.isLoggedIn) {
      this.goToHome();
    }
  }

  async login() {
    if (
      this.username === "premraj@ssppune.com" &&
      this.password === "Omom#2008#"
    ) {
      this.liveData.state.isLoggedIn = true;
      this.liveData.state.user = "Premraj";
      this.goToHome();
    } else if (
      this.username === "mngl@gmail.com" &&
      this.password === "Mngl#2021#"
    ) {
      this.liveData.state.isLoggedIn = true;
      this.liveData.state.user = "MNGL";
      this.goToHome();
    } else {
      window.alert("Please enter valid credentials");
    }
  }

  goToHome() {
    this.$router.push("/home");
  }
}
